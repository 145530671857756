<template>
  <cz-dialog
    v-bind="$attrs"
    v-on="$listeners"
    title="בחירת הזמנות"
    max-width="90%"
    show-actions
    :allow-overflow="false"
  >
    <cz-data-table
      v-model="selectedItems"
      v-bind="ordersTable"
      :items="filterableItems"
      :loading="loading"
      show-select
      :single-select="!multiselect"
      height="calc(100vh - 450px)"
      item-key="_id"
    >
      <template #top>
        <v-toolbar class="py-4">
          <cz-input
            v-model="searchText"
            dense
            :prepend-inner-icon="mdiMagnify"
            style="max-width: 350px"
            :placeholder="$t('common.searchOrders')"
          />
        </v-toolbar>
      </template>
      <template #header.data-table-select="{ on, props }" v-if="multiselect">
        <cz-checkbox v-bind="props" v-on="on" />
      </template>
      <template
        #item.data-table-select="{ item, on, attrs, select, isSelected }"
      >
        <cz-checkbox :input-value="isSelected" @change="select" />
      </template>
      <template #item.totalAmount="{ item }">
        {{ formatAmountWithCurrency(item.totalAmount, item.currency) }}
      </template>

      <template #item.amountLeft="{ item }">
        {{ formatAmountWithCurrency(item.amountLeft, item.currency) }}
      </template>

      <template #item.issueDate="{ item }">
        <span v-if="item.issueDate">{{
          formatDateToDisplay(item.issueDate)
        }}</span>
      </template>
      <template #item.createdAt="{ item }">
        <span v-if="item.createdAt">{{
          formatDateToDisplay(item.createdAt)
        }}</span>
      </template>
    </cz-data-table>

    <template #actions>
      <div class="d-flex flex-column full-width pt-4" style="gap: 10px">
        <v-divider style="position: absolute; left: 0; right: 0" />
        <div class="d-flex justify-end full-width mt-6" style="gap: 20px">
          <cz-button
            :width="120"
            large
            class="button--text"
            :title="$t('common.close')"
            @click="$emit('close')"
          />
          <cz-button
            color="info"
            large
            :width="120"
            :disabled="!selectedItems.length"
            :title="$t('common.confirm')"
            @click="$emit('done', selectedItems)"
          />
        </div>
      </div>
    </template>
  </cz-dialog>
</template>

<script>
import {
  CzDialog,
  CzDataTable,
  CzCheckbox,
  CzButton,
  CzInput
} from '@/components';
import { formatAmountWithCurrency } from '@/shared/services/currency.service';
import { formatDateToDisplay } from '@/shared/services/dates.service';
import { mdiMagnify } from '@mdi/js';
import cloneDeep from 'lodash.clonedeep';
export default {
  name: 'OrderLookupDialog',
  components: {
    CzDataTable,
    CzDialog,
    CzCheckbox,
    CzInput,
    CzButton
  },
  props: {
    vendorId: {
      type: String,
      required: true
    },
    multiselect: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean
    },
    items: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    filterableItems() {
      if (!this.searchText) {
        return this.items;
      }
      const _items = cloneDeep(this.items);
      return _items.filter((item) => {
        return (
          item?.title?.includes(this.searchText) ||
          item?.orderNumber?.toString()?.includes(this.searchText)
        );
      });
    }
  },
  data() {
    return {
      mdiMagnify,
      selectedItems: [],
      searchText: '',
      ordersTable: {
        columns: [
          {
            text: this.$t('customer.orders.orderNumber'),
            value: 'orderNumber',
            sortable: true,
            filterable: true,
            width: 150
          },
          {
            text: this.$t('customer.orders.orderStatus'),
            value: 'statusText',
            sortable: true,
            filterable: true,
            width: 150
          },
          {
            text: this.$t('customer.orders.orderShortDesc'),
            value: 'title',
            sortable: false,
            width: 200
          },

          {
            text: this.$t('customer.orders.totalAmount'),
            value: 'totalAmount',
            sortable: true,
            width: 170
          },
          {
            text: this.$t('customer.orders.amountLeft'),
            value: 'amountLeft',
            sortable: true,
            width: 130
          },
          {
            text: this.$t('vendor.orderSubmissions.createdAt'),
            value: 'createdAt',
            sortable: true
          },
          {
            text: this.$t('vendor.orderSubmissions.issueDate'),
            value: 'issueDate',
            sortable: true
          }
        ]
      }
    };
  },
  methods: {
    formatDateToDisplay,
    formatAmountWithCurrency
  }
};
</script>

<style lang="scss" scoped></style>
