<template>
  <v-form class="position-relative" :disabled="loading" v-if="submission">
    <v-row class="px-2 pb-6">
      <v-col :cols="12"
        ><div class="text-title font-weight-semibold">פרטים כלליים</div></v-col
      >
      <v-col :xl="3" :lg="4" :md="6" :sm="12">
        <cz-form-field
          :label="$t('vendor.orders.documentNumber')"
          :required="!readOnlyMode"
        >
          <validation-provider
            :rules="!readOnlyMode ? 'required' : ''"
            :name="$t('customer.orders.orderNumber')"
            v-slot="{ errors }"
          >
            <cz-input
              type="number"
              v-model="submission.documentNumber"
              :error-messages="errors"
              dense
              :readonly="readOnlyMode"
            />
          </validation-provider>
        </cz-form-field>
      </v-col>
      <v-col :xl="3" :lg="4" :md="6" :sm="12">
        <cz-form-field
          :label="$t('vendor.orderSubmissions.submissionDocumentType')"
          :required="!readOnlyMode"
        >
          <validation-provider
            :rules="!readOnlyMode ? 'required' : ''"
            :name="$t('vendor.orderSubmissions.submissionDocumentType')"
            v-slot="{ errors }"
          >
            <cz-autocomplete
              v-model="submission.documentType"
              dense
              :error-messages="errors"
              :items="submissionDocumentTypes"
              :readonly="readOnlyMode"
              @change="onDocumentTypeChange"
            />
          </validation-provider>
        </cz-form-field>
      </v-col>
      <v-col :lg="3" :md="4" :sm="12" v-if="isAllocationNumberVisible">
        <cz-form-field :label="$t('common.assignmentNumber')">
          <validation-provider
            rules="length:9"
            :name="$t('common.assignmentNumber')"
            v-slot="{ errors }"
          >
            <cz-input
              v-model="submission.assignmentNumber"
              type="number"
              dense
              :error-messages="errors"
              :readonly="readOnlyMode"
            />
          </validation-provider>
        </cz-form-field>
      </v-col>
      <v-col :xl="3" :lg="4" :md="6" :sm="12">
        <cz-form-field
          :label="$t('vendor.orders.status')"
          :required="!readOnlyMode"
          class="mb-4"
        >
          <div class="d-flex align-center" style="column-gap: 5px">
            <div class="text-body-1 font-weight-semibold">
              {{ orderSubmissionStatusText[submission.status] }}
            </div>
            <cz-menu-button
              :max-width="300"
              :menu-max-width="350"
              flat
              color="info"
              :button-icon-src="mdiInformationOutline"
              :menuItems="submissionStatusMenuItems"
            />
          </div>
        </cz-form-field>
      </v-col>
      <v-col :xl="3" :lg="4" :md="6" :sm="12">
        <cz-form-field
          :label="$t('customer.orders.issueDate')"
          :required="!readOnlyMode"
        >
          <validation-provider
            rules="required|date_format:yyyy-MM-dd"
            :name="$t('customer.orders.issueDate')"
            v-slot="{ errors }"
          >
            <cz-new-date-picker
              v-model="submission.issueDate"
              open-menu-on-click
              :readonly="readOnlyMode"
              dense
              :error-messages="errors"
            />
          </validation-provider>
        </cz-form-field>
      </v-col>
      <v-col :xl="3" :lg="4" :md="6" :sm="12" v-if="isOrderlessSource">
        <cz-form-field
          :label="$t('customer.orders.vendorId')"
          :required="!readOnlyMode"
        >
          <validation-provider
            rules="required|length:9"
            :name="$t('customer.orders.vendorId')"
            v-slot="{ errors }"
          >
            <cz-input
              v-model="submission.vendorId"
              :error-messages="errors"
              :append-icon="null"
              @change="onVendorIdChange"
              dense
              :readonly="readOnlyMode"
            />
          </validation-provider>
        </cz-form-field>
      </v-col>
      <v-col
        :xl="3"
        :lg="4"
        :md="6"
        :sm="12"
        v-if="!(isOrderlessSource && !readOnlyMode)"
      >
        <cz-form-field :label="$t('customer.orders.orderNumber')">
          <div v-if="submission.relatedOrder">
            <order-info-menu
              :order="submission.relatedOrder"
              @show-order="$emit('show-order')"
            />
          </div>
          <span
            class="text-subtitle-1 font-weight-semibold"
            v-else-if="isOrderlessSource"
            >הגשה ללא הזמנה</span
          >
        </cz-form-field>
      </v-col>
      <v-col
        :xl="3"
        :lg="4"
        :md="6"
        :sm="12"
        v-if="
          submission.parentSubmission && !(isOrderlessSource && !readOnlyMode)
        "
      >
        <cz-form-field :label="$t('customer.orders.parentSubmissionNumber')">
          <div>
            <submission-info-menu
              :submission="submission.parentSubmission"
              @show-submission="$emit('nav-to-parent-submission')"
            />
          </div>
        </cz-form-field>
      </v-col>
      <template
        v-if="
          submission.vendorId &&
          submission.vendorId.length === 9 &&
          isOrderlessSource &&
          !readOnlyMode
        "
      >
        <v-col :cols="12"
          ><div class="text-title font-weight-semibold">
            {{ $t('customer.orderlessSubmissions.orderlessAdditionalDetails') }}
          </div></v-col
        >

        <v-col :xl="3" :lg="4" :md="6" :sm="12">
          <cz-form-field :label="$t('customer.orders.orderNumber')">
            <cz-input
              v-model="submission.relatedOrderNumber"
              dense
              :disabled="!isValidVendorId"
              readonly
              readonly-border
              :append-icon="mdiMagnify"
              @click="onOrdersClick"
              @click:clear="onClearOrderNumber"
              placeholder="לחץ לבחירת הזמנה"
              clearable
              hint="הזן מספר הזמנה במידה וקיים אחד"
              persistent-hint
            />
          </cz-form-field>
        </v-col>

        <v-col
          :xl="3"
          :lg="4"
          :md="6"
          :sm="12"
          v-if="isParentSubmissionFieldVisible"
        >
          <cz-form-field :label="$t('customer.orders.parentSubmissionNumber')">
            <validation-provider
              :rules="isParentSubmissionFieldRequired ? 'required' : ''"
              :name="$t('customer.orders.parentSubmissionNumber')"
              v-slot="{ errors }"
            >
              <cz-input
                :value="
                  submission.parentSubmissionId
                    ? submission.parentSubmission.documentNumber
                    : ''
                "
                dense
                :error-messages="errors"
                readonly-border
                readonly
                :append-icon="mdiMagnify"
                @click="onParentSubmissionClick"
                @click:clear="onClearParentSubmission"
                placeholder="לחץ לבחירת הגשת אב"
                clearable
              />
            </validation-provider>
          </cz-form-field>
        </v-col>
      </template>
    </v-row>
    <v-divider class="mb-4" />
    <v-row>
      <v-col :cols="12"
        ><div class="text-title font-weight-semibold">סכום</div></v-col
      >
      <v-col :xl="3" :lg="4" :md="6" :sm="12">
        <cz-form-field
          :label="$t('customer.orders.netAmount')"
          :required="!readOnlyMode"
        >
          <validation-provider
            rules="required"
            :name="$t('customer.orders.netAmount')"
            v-slot="{ errors }"
          >
            <cz-input
              v-model="submission.netAmount"
              type="number"
              :error-messages="errors"
              dense
              :readonly="readOnlyMode"
            />
          </validation-provider>
        </cz-form-field>
      </v-col>
      <v-col :xl="3" :lg="4" :md="6" :sm="12">
        <cz-form-field
          :label="$t('customer.orders.vatAmount')"
          :required="!readOnlyMode"
        >
          <validation-provider
            rules="required"
            :name="$t('customer.orders.vatAmount')"
            v-slot="{ errors }"
          >
            <cz-input
              v-model="submission.vatAmount"
              type="number"
              :error-messages="errors"
              dense
              :readonly="readOnlyMode"
            />
          </validation-provider>
        </cz-form-field>
      </v-col>
      <v-col :xl="3" :lg="4" :md="6" :sm="12">
        <cz-form-field label="סכום חשבונית כולל" :required="!readOnlyMode">
          <validation-provider
            rules="required"
            :name="'סכום חשבונית כולל'"
            v-slot="{ errors }"
          >
            <cz-input
              v-model="submission.totalAmount"
              type="number"
              :error-messages="errors"
              dense
              :readonly="readOnlyMode"
            />
          </validation-provider>
        </cz-form-field>
      </v-col>
      <v-col :xl="3" :lg="4" :md="6" :sm="12">
        <cz-form-field
          :label="$t('vendor.submissionCurrency')"
          :required="!readOnlyMode"
        >
          <validation-provider
            rules="required"
            :name="$t('customer.orders.currency')"
            v-slot="{ errors }"
          >
            <cz-currency-picker
              v-model="submission.currency"
              :items="currencyTypes"
              :error-messages="errors"
              outlined
              dense
              :readonly="readOnlyMode"
            />
          </validation-provider>
        </cz-form-field>
      </v-col>
      <v-col :xl="3" :lg="4" :md="6" :sm="12" v-if="isOrderlessSource">
        <cz-form-field :label="$t('common.tags')">
          <cz-input-tags
            v-model="submission.tags"
            :items="systemTags"
            :readonly="readOnlyMode"
            text-color="info"
            color="buttonSecondary"
          />
        </cz-form-field>
      </v-col>
    </v-row>
    <v-divider class="mb-4" />
    <v-row>
      <v-col :cols="12" v-if="hasAttachedDocs">
        <div class="d-flex align-center" style="column-gap: 20px">
          <div class="text-title font-weight-semibold">
            {{
              $t('vendor.orderSubmissions.attachedDocuments', [
                additionalDocumentsCount
              ])
            }}
          </div>
          <cz-button
            small
            :icon-src="showDocs ? mdiEye : mdiEyeOff"
            color="primary"
            dark
            :outlined="!showDocs"
            :title="showDocs ? $t('common.hide') : $t('common.show')"
            :icon-size="23"
            @click="showDocs = !showDocs"
          />
        </div>
      </v-col>
      <template v-for="item in exisitingDocuments">
        <v-col :lg="4" :md="6" :sm="12" :key="item._id">
          <v-card
            class="d-flex align-center"
            hover
            outlined
            @click="viewDocument(item)"
          >
            <v-card-text>
              <div class="d-flex" style="column-gap: 10px">
                <cz-icon :size="44" :src="mdiFilePdfBox" color="pdf" />
                <div>
                  <div
                    class="text-body-1 font-weight-semibold textPrimary--text"
                  >
                    {{ item.typeText }}
                  </div>
                  <div
                    class="textPrimary--text text-subtitle-2"
                    style="opacity: 0.5"
                  >
                    {{
                      $t('vendor.orderSubmissions.documentUploadedAt', [
                        formatDateTimeToDisplay(item.createdAt)
                      ])
                    }}
                  </div>
                </div>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </template>
      <v-col :cols="12" v-if="!readOnlyMode">
        <div class="text-title mb-4 font-weight-semibold">
          {{ $t('vendor.orderSubmissions.additionalDocuments') }}
        </div>
        <cz-button
          :title="$t('vendor.orderSubmissions.addAddtionalDocument')"
          :icon-src="mdiPlus"
          color="buttonSecondary"
          @click="onAddDocument"
        />
      </v-col>
      <template v-for="(item, index) in additionalDocumentsLocal">
        <v-col :lg="6" :md="6" :sm="12" :key="`add_doc_file_${index}`">
          <cz-form-field
            :label="$t('vendor.orderSubmissions.additionalDocumentFile')"
          >
            <cz-dropzone
              accept="application/pdf"
              @change="onAdditionalDocumentChange($event, index)"
              :height="55"
              :title="$t('dropzone.title')"
              :hide-title="!!item.file"
            >
              <div slot="files" class="py-2 dropzone-files" v-if="item.file">
                <cz-chip
                  v-if="item.file"
                  :text="item.file.name"
                  dark
                  color="secondary"
                  circle-edges
                  removeable
                  small
                  @remove="item.file = null"
                />
              </div>
            </cz-dropzone>
          </cz-form-field>
        </v-col>
        <v-col :lg="6" :md="6" :sm="12" :key="`add_doc_type_${index}`">
          <div class="d-flex align-center" style="column-gap: 10px">
            <cz-form-field
              class="flex-grow-1"
              :label="$t('vendor.orderSubmissions.additionalDocumentTypeLabel')"
            >
              <validation-provider
                :rules="item.file ? 'required' : ''"
                :name="
                  $t('vendor.orderSubmissions.additionalDocumentTypeLabel')
                "
                v-slot="{ errors }"
              >
                <cz-autocomplete
                  v-model="item.type"
                  :items="addtionalDocumentTypes"
                  outlined
                  :error-messages="errors"
                  color="primary"
                  :placeholder="
                    $t(
                      'vendor.orderSubmissions.additionalDocumentTypePlaceholder'
                    )
                  "
                  :readonly="readOnlyMode"
                />
              </validation-provider>
            </cz-form-field>
            <cz-button
              :icon-src="mdiCloseCircleOutline"
              icon-color="negative"
              text
              @click="onRemoveDocument(index)"
            />
          </div>
        </v-col>
      </template>
    </v-row>

    <order-lookup-dialog
      v-model="orderLookupDialog.show"
      v-if="orderLookupDialog.show"
      @close="orderLookupDialog.show = false"
      @done="onOrderSelected"
      v-bind="orderLookupDialog"
    />

    <submission-lookup-dialog
      v-model="submissionLookupDialog.show"
      v-if="submissionLookupDialog.show"
      @close="submissionLookupDialog.show = false"
      @done="onParentSubmissionSelected"
      v-bind="submissionLookupDialog"
    />
  </v-form>
</template>

<script>
import {
  CzInput,
  CzFormField,
  CzButton,
  CzDropzone,
  CzChip,
  CzIcon,
  CzAutocomplete,
  CzMenuButton,
  CzCurrencyPicker,
  CzInputTags,
  CzNewDatePicker
} from '@/components';
import cloneDeep from 'lodash.clonedeep';
import isEqual from 'lodash.isequal';
import OrderSubmissionDocumentType from '@/shared/types/OrderSubmissionDocumentType';
import OrderLookupDialog from '@/domain/shared/components/OrderLookupDialog.vue';
import SubmissionLookupDialog from '@/domain/shared/components/SubmissionLookupDialog.vue';
import {
  mdiFileDocumentOutline,
  mdiEye,
  mdiFilePdfBox,
  mdiEyeOff,
  mdiPlus,
  mdiMagnify,
  mdiTrashCanOutline,
  mdiInformationOutline,
  mdiCloseCircleOutline
} from '@mdi/js';
import currencyTypes from '@/shared/types/CurrencyTypes';
import OrderSubmissionStatus from '@/shared/types/OrderSubmissionStatus';
import { typesMixin } from '@/shared/mixins';
import OrderInfoMenu from '@/domain/customer/components/OrderInfoMenu.vue';
import SubmissionInfoMenu from '@/domain/customer/components/SubmissionInfoMenu.vue';
import { formatAmountWithCurrency } from '@/shared/services/currency.service';
import { formatDateTimeToDisplay } from '@/shared/services/dates.service';
import {
  getAllDocTypes,
  getParentSubmissionDocTypesForDocTypeByVendor,
  submissionDocTypes
} from '../constants';
import { getOrders, getOrderSubmissions } from '@/core/api';
import OrderStatus from '@/shared/types/OrderStatus';
import SubmissionDocType from '@/shared/types/SubmissionDocType';
export default {
  name: 'SubmissionForm',
  components: {
    CzInput,
    CzAutocomplete,
    CzFormField,
    CzDropzone,
    CzButton,
    CzInputTags,
    CzCurrencyPicker,
    CzChip,
    CzNewDatePicker,
    CzMenuButton,
    OrderInfoMenu,
    SubmissionInfoMenu,
    CzIcon,
    OrderLookupDialog,
    SubmissionLookupDialog
  },
  mixins: [typesMixin],
  props: {
    value: {
      type: Object,
      required: true
    },
    isVendorHomeRoute: {
      type: Boolean
    },
    loading: {
      type: Boolean
    },
    systemTags: {
      type: Array,
      default: () => []
    },
    readOnlyMode: {
      type: Boolean
    },
    additionalDocuments: {
      type: Array,
      default: () => []
    },
    isVatExempt: {
      type: Boolean
    }
  },
  computed: {
    isAllocationNumberVisible() {
      return ['invoice', 'transaction-account', 'invoice-receipt'].includes(
        this.submission?.documentType
      );
    },
    isParentSubmissionVisible() {},
    isOrderlessSource() {
      return this.submission?.source === 'orderless';
    },
    addtionalDocumentTypes() {
      return [
        {
          text: this.$t(
            'vendor.orderSubmissions.additionalDocumentTypes.performanceReport'
          ),
          value: OrderSubmissionDocumentType.PERFORMANCE_REPORT
        },
        {
          text: this.$t(
            'vendor.orderSubmissions.additionalDocumentTypes.subContactInvoiceCopy'
          ),
          value: OrderSubmissionDocumentType.SUBCONTACT_INV_COPY
        },
        {
          text: this.$t(
            'vendor.orderSubmissions.additionalDocumentTypes.reference'
          ),
          value: OrderSubmissionDocumentType.REFERENCE
        },
        {
          text: this.$t(
            'vendor.orderSubmissions.additionalDocumentTypes.bookKeepingClearance'
          ),
          value: OrderSubmissionDocumentType.BOOK_KEEP_CLEAR
        },
        {
          text: this.$t(
            'vendor.orderSubmissions.additionalDocumentTypes.taxDeductionAuthorization'
          ),
          value: OrderSubmissionDocumentType.TAX_DEDUCTION_AUTH
        },
        {
          text: this.$t(
            'vendor.orderSubmissions.additionalDocumentTypes.deliveryCertification'
          ),
          value: OrderSubmissionDocumentType.DELIVERY_CERT
        }
      ];
    },
    additionalDocumentsCount() {
      return this.submission?.additionalDocuments?.length || 0;
    },
    exisitingDocuments() {
      if (this.submission?.additionalDocuments?.length && this.showDocs) {
        return this.submission.additionalDocuments;
      } else {
        return [];
      }
    },
    hasAttachedDocs() {
      return this.submission?.additionalDocuments?.length > 0;
    },
    submissionStatusMenuItems() {
      switch (this.submission?.status) {
        case OrderSubmissionStatus.NEW_PREDICTED:
          return [
            {
              title: this.$t('vendor.orderSubmissions.status.new_predicted'),
              subtitle: this.$t(
                'vendor.orderSubmissions.status.new_predicted_hint'
              )
            }
          ];
        case OrderSubmissionStatus.PENDING_APPROVAL:
          return [
            {
              title: this.$t('customer.vendorSubmissions.pendingApprovalTitle'),
              subtitle: this.$t(
                'customer.vendorSubmissions.pendingApprovalSubtitle'
              )
            }
          ];
        case OrderSubmissionStatus.RETURNED:
          return [
            {
              title: this.$t('customer.vendorSubmissions.returnType'),
              subtitle: this.submission?.returnTypeText
            },
            {
              title: this.$t('customer.vendorSubmissions.note'),
              subtitle:
                this.submission?.note ||
                this.$t('customer.vendorSubmissions.noNotes')
            }
          ];
        case OrderSubmissionStatus.REJECTED:
          return [
            {
              title: this.$t('customer.vendorSubmissions.rejectType'),
              subtitle: this.submission?.rejectTypeText
            },
            {
              title: this.$t('customer.vendorSubmissions.note'),
              subtitle:
                this.submission?.note ||
                this.$t('customer.vendorSubmissions.noNotes')
            }
          ];

        default:
          return null;
      }
    },
    submissionDocumentTypes() {
      if (this.isOrderlessSource) {
        return getAllDocTypes(this);
      } else {
        return submissionDocTypes(
          this,
          this.isVatExempt,
          this.submission?.parentSubmission?.documentType || null
        );
      }
    },
    isParentSubmissionFieldVisible() {
      return (
        ![
          SubmissionDocType.TRANSACTION_ACCOUNT,
          SubmissionDocType.INVOICE
        ].includes(this.submission?.documentType) &&
        !!this.submission?.documentType
      );
    },
    isParentSubmissionFieldRequired() {
      if (!this.isOrderlessSource) {
        return false;
      }

      return [
        SubmissionDocType.CREDIT_INVOICE,
        SubmissionDocType.RECEIPT
      ].includes(this.submission?.documentType);
    },
    isParentSubmission() {
      return this.submission?.parentSubmissionId ? false : true;
    },
    isValidVendorId() {
      return this.submission?.vendorId?.length === 9;
    }
  },
  data() {
    return {
      mdiMagnify,
      mdiFilePdfBox,
      mdiFileDocumentOutline,
      mdiCloseCircleOutline,
      mdiEye,
      mdiEyeOff,
      mdiTrashCanOutline,
      mdiInformationOutline,
      mdiPlus,
      currencyTypes,
      showDocs: true,
      additionalDocumentsLocal: [],
      submission: null,
      parentSubmissions: [],
      orders: [],
      searchingOrders: false,
      searchingParentSubmissions: false,
      orderLookupDialog: {
        show: false,
        vendorId: null,
        items: [],
        loading: false,
        multiselect: false
      },
      submissionLookupDialog: {
        show: false,
        vendorId: null,
        items: [],
        loading: false,
        multiselect: false
      }
    };
  },
  methods: {
    formatAmountWithCurrency,
    formatDateTimeToDisplay,
    onAddDocument() {
      // add empty submission document to list
      this.additionalDocumentsLocal.push({
        type: '',
        file: null
      });
    },
    onRemoveDocument(index) {
      this.additionalDocumentsLocal.splice(index, 1);
    },
    onDocumentTypeChange() {
      this.$set(this.submission, 'parentSubmissionId', undefined);
      this.$set(this.submission, 'parentSubmission', null);
    },
    onAdditionalDocumentChange(files, index) {
      this.additionalDocumentsLocal[index].file = files[0];
    },
    viewDocument(item) {
      window.open(item.file.url, '_blank');
    },
    onParentSubmissionsSearch(value) {},
    onOrdersSearch(value) {},
    async onVendorIdChange(value) {
      this.submission.relatedOrderNumber = '';
      this.submission.relatedOrderId = '';
      this.submission.relatedOrder = null;

      if (!value || value.length < 9) {
        this.orders = [];
        return;
      }
    },
    onOrdersClick() {
      this.orderLookupDialog.vendorId = this.submission.vendorId;
      this.orderLookupDialog.show = true;
      this.orderLookupDialog.items = [];
      this.loadOrders();
    },
    onClearOrderNumber() {
      this.submission.relatedOrderId = null;
      this.submission.relatedOrderNumber = null;
      this.submission.relatedOrder = null;
    },
    onParentSubmissionClick() {
      this.submissionLookupDialog.vendorId = this.submission.vendorId;
      this.submissionLookupDialog.show = true;
      this.submissionLookupDialog.items = [];
      this.loadParentSubmissions();
    },
    onClearParentSubmission() {
      this.submission.parentSubmissionId = null;
      this.submission.parentSubmission = null;
    },
    async loadOrders() {
      try {
        this.orderLookupDialog.loading = true;
        const { data } = await getOrders({
          status: {
            $in: [OrderStatus.OPEN, OrderStatus.OPEN_PENDING_VENDOR_REG]
          },
          accountId: this.submission.accountId,
          vendorId: this.submission.vendorId,
          documentType: 'order'
        });

        this.orderLookupDialog.items = data;
      } finally {
        this.orderLookupDialog.loading = false;
      }
    },
    async loadParentSubmissions() {
      try {
        this.submissionLookupDialog.loading = true;
        const docTypes = getParentSubmissionDocTypesForDocTypeByVendor(
          this,
          this.submission?.documentType,
          this.isVatExempt
        );

        const query = {
          vendorId: this.submission.vendorId,
          customerAccountId: this.submission.accountId,
          parentSubmissionId: { $exists: false },
          status: OrderSubmissionStatus.APPROVED,
          documentType: { $in: docTypes.map((item) => item.value) }
        };

        if (this.submission.documentType !== SubmissionDocType.CREDIT_INVOICE) {
          query.documentStatus = 'open';
        }

        const { data } = await getOrderSubmissions(query);
        this.submissionLookupDialog.items = data;
      } finally {
        this.submissionLookupDialog.loading = false;
      }
    },
    onOrderSelected(items) {
      this.orderLookupDialog.show = false;
      this.$set(this.submission, 'relatedOrderId', items[0]._id);
      this.submission.relatedOrderNumber = items[0].orderNumber;
      this.submission.relatedOrder = items[0];
    },
    onParentSubmissionSelected(items) {
      this.submissionLookupDialog.show = false;
      this.$set(this.submission, 'parentSubmissionId', items[0]._id);
      this.submission.parentSubmission = items[0];
    }
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler(value) {
        if (!isEqual(value, this.submission)) {
          this.submission = cloneDeep(this.value);
        }
      }
    },
    submission: {
      deep: true,
      handler(value) {
        this.$emit('input', value);
      }
    },
    additionalDocuments: {
      immediate: true,
      deep: true,
      handler(value) {
        if (!isEqual(value, this.additionalDocumentsLocal)) {
          this.additionalDocumentsLocal = cloneDeep(value);
        }
      }
    },
    additionalDocumentsLocal: {
      deep: true,
      handler(value) {
        this.$emit('update:additionalDocuments', value);
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
